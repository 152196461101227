.horizontalMenu {
  html, body, iframe, h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
	color: #000 !important;
    vertical-align: baseline;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    font-family: Helvetica, sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
    font-smoothing: antialiased;
    font-smooth: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: subpixel-antialiased;
    font-smoothing: subpixel-antialiased;
    font-smooth: subpixel-antialiased;
  }

  .cl {
    clear: both;
  }

  img {
    border: 0 none;
    max-width: 100%;
  }
}

object, embed, video {
  border: 0 none;
  max-width: 100%;
}

.horizontalMenu {
  a:focus {
    outline: none;
  }

  &:before {
    content: "";
    display: table;
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

/* ================== Desktop Main Menu CSS ================== */

.horizontalMenucontainer {
  background-size: cover;
  overflow: hidden;
  background-attachment: fixed;
  background-position: 50% 0;
  background-repeat: no-repeat;
}

.horizontal-main {
  width: 100%;
  position: relative;
}

.horizontal-mainwrapper {
  margin: 0 auto;
}

.desktoplogo {
  padding: 0.75rem;
  margin: 0;
  float: left;

  img {
    width: 100%;
  }
}

.horizontalMenu {
  color: #fff;
  font-size: 14px;
  padding: 0;
  display: block;

  > .horizontalMenu-list {
    text-align: left;
    margin: 0 auto 0 auto;
    width: 100%;
    display: block;
    padding: 0;

    > li {
      text-align: center;
      display: block;
      padding: 0;
      margin: 0;
      float: left;

      > a {
        display: block;
        padding: 16px;
        text-decoration: none;
        position: relative;
        color: #495584;
        border-right: 1px solid #eaedf1;
      }

      &:last-child > a {
        border-right: 0px;
      }

      a.menuhomeicon {
        padding-left: 25px;
        padding-right: 25px;
      }

      > a {
        i {
          display: inline-block;
          font-size: 16px;
          line-height: inherit;
          margin-right: 4px;
          vertical-align: bottom;
        }
        .horizontal-icon{
          font-size: 12px;
          line-height: 1.8;
        }
        .arrow:after {
          border-left: 4px solid rgba(0, 0, 0, 0);
          border-right: 4px solid rgba(0, 0, 0, 0);
          border-top: 4px solid;
          content: "";
          float: right;
          right: 11px;
          height: 0;
          margin: 0 0 0 14px;
          position: absolute;
          text-align: right;
          top: 33px;
          width: 0;
        }
      }

      &.rightmenu {
        float: right;

        a {
          padding: 0px 30px 0px 20px;
          border-right: none;

          i {
            font-size: 15px;
          }
        }

        float: right;

        &:before {
          content: "";
          display: table;
        }
      }
    }
  }
}

/* Desktop Search Bar */

.horizontalMenu-list > li.rightmenu:after {
  content: "";
  display: table;
}

.horizontalMenu > .horizontalMenu-list > li {
  &.rightmenu {
    &:after {
      clear: both;
    }

    > .topmenusearch {
      float: right;
      width: 210px;
      height: 39px;
      position: relative;
      margin: 16px 0px 0px 0px;

      .searchicon {
        -webkit-transition: all 0.7s ease 0s;
        -moz-transition: all 0.7s ease 0s;
        -o-transition: all 0.7s ease 0s;
        transition: all 0.7s ease 0s;
      }

      input {
        width: 100%;
        position: relative;
        float: right;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        border: 0;
        padding: 0;
        margin: 0;
        text-indent: 15px;
        height: 39px;
        z-index: 2;
        outline: none;
        color: #333;
        background-color: #efefef;
        -webkit-transition: all 0.7s ease 0s;
        -moz-transition: all 0.7s ease 0s;
        -o-transition: all 0.7s ease 0s;
        transition: all 0.7s ease 0s;
        font-size: 12px;

        &::placeholder {
          color: #a9a9a9;
        }

        &:focus {
          color: #333;
          width: 230px;

          ~ {
            .btnstyle .fa {
              color: #000;
              opacity: 1;
            }

            .searchicon {
              opacity: 1;
              z-index: 3;
              color: #FFFFFF;
            }
          }
        }
      }

      .btnstyle {
        top: 0px;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 40px;
        line-height: 30px;
        z-index: 1;
        cursor: pointer;
        opacity: 0.3;
        color: #333;
        z-index: 1000;
        background-color: transparent;
        border: solid 0px;
        -webkit-transition: all 0.7s ease 0s;
        -moz-transition: all 0.7s ease 0s;
        -o-transition: all 0.7s ease 0s;
        transition: all 0.7s ease 0s;

        .fa {
          line-height: 37px;
          margin: 0;
          padding: 0;
          text-align: center;
        }

        &:hover .fa {
          opacity: 1;
        }
      }
    }

    zoom: 1;
  }

  > {
    ul.sub-menu {
      position: absolute;
      top: 50px;
      z-index: 1000;
      margin: 0px;
      padding: 5px;
      min-width: 190px;
      background-color: #fff;
      box-shadow: 0 10px 15px 0 rgba(104, 113, 123 , .3);
      border: 1px solid #f0f0f4;

      > li {
        position: relative;
        margin: 0px;
        padding: 0px;
        display: block;

        > {
          a {
            background-image: none;
            color: #76839a;
            border-right: 0 none;
            text-align: left;
            display: block;
            line-height: 22px;
            padding: 8px 12px;
            text-transform: none;
            font-size: 14px;
            letter-spacing: normal;
            border-right: 0px solid;

            &:hover {
              text-decoration: none;
            }

            > i {
              margin-right: 9px;
            }
          }

          ul.sub-menu {
            min-width: 220px;
            position: absolute;
            left: 100%;
            top: 0;
            margin: 0;
            padding: 0;
            list-style: none;
            background-color: #fff;
            box-shadow: 0 10px 15px 0 rgba(104, 113, 123 , .3);
            border: 1px solid #f0f0f4;

            > li {
              position: relative;
              margin: 0px;
              padding: 0px;
              display: block;

              > {
                a {
                  background-image: none;
                  color: #76839a;
                  border-right: 0 none;
                  text-align: left;
                  display: block;
                  line-height: 22px;
                  padding: 8px 12px;
                  text-transform: none;
                  font-size: 14px;
                  letter-spacing: normal;
                  border-right: 0px solid;

                  &:hover {
                    text-decoration: none;
                  }

                  > i {
                    margin-right: 9px;
                  }
                }

                ul.sub-menu {
                  min-width: 220px;
                  position: absolute;
                  left: 100%;
                  top: 0;
                  margin: 0px;
                  list-style: none;
                  padding: 0px;
                  background-color: #fff;
                  box-shadow: 0 10px 15px 0 rgba(104, 113, 123 , .3);
                  border: 1px solid #f0f0f4;
                  > li {
                    position: relative;
                    margin: 0px;
                    padding: 0px;
                    display: block;

                    > a {
                      background-image: none;
                      color: #76839a;
                      border-right: 0 none;
                      text-align: left;
                      display: block;
                      line-height: 22px;
                      padding: 8px 12px;
                      text-transform: none;
                      font-size: 13px;
                      letter-spacing: normal;
                      border-right: 0px solid;

                      &:hover {
                        text-decoration: none;
                      }

                      > i {
                        margin-right: 9px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .horizontal-megamenu {
      width: 100%;
      left: 0px;
      position: absolute;
      top: 50px;
      color: #25252a;
      z-index: 1000;
      margin: 0px;
      text-align: left;
      font-size: 15px;

      .megamenu-content {
        border: 1px solid #e8ebf3;
        background-color: #fff;
        padding: 14px 20px;
        border-radius: 2px;
        box-shadow: 0px 5px 25px rgba(227, 228, 238, 0.2);
      }

      .title {
        padding: 5px 5px 5px 0px;
        font-size: 16px;
        color: #2c2c2c;
        margin: 0px 0px 4px 0px;
        text-align: left;
        font-weight: 600;
      }

      .wsmwnutxt {
        width: 100%;
        font-size: 14px;
        text-align: justify;
        line-height: 22px;
        color: #2c2c2c;
        margin-top: 10px;
      }

      .link-list li {
        display: block;
        text-align: center;
        white-space: nowrap;
        text-align: left;
        padding: 5px 10px 5px 10px;

        a {
          line-height: 18px;
          border-right: none;
          text-align: left;
          padding: 6px 0px;
          background: #fff;
          background-image: none;
          color: #25252a;
          border-right: 0 none;
          display: block;
          font-size: 14px;
        }
      }

      li {
        i {
          margin-right: 5px;
          text-align: center;
          width: 18px;
        }

        a:hover {
          background: transparent;
          text-decoration: none;
        }
      }

      .link-list li i {
        font-size: 12px;
      }

      li i {
        margin-right: 5px;
        text-align: center;
        width: 18px;
      }

      .mrgtop {
        margin-top: 15px;
      }

      .show-grid div {
        padding-bottom: 10px;
        padding-top: 10px;
        background-color: #dbdbdb;
        border: 1px solid #e7e7e7;
        color: #6a6a6a;
        margin: 2px 0px;
        font-size: 13px;
      }

      &.halfmenu {
        width: 30%;
        right: auto;
        left: auto;
      }

      &.halfdiv {
        width: 20%;
        right: auto;
        left: auto;
      }

      .menu_form {
        width: 100%;
        display: block;

        input[type="text"] {
          width: 100%;
          border: 1px solid #e2e2e2;
          color: #000;
          font-size: 13px;
          padding: 8px 5px;
          margin-bottom: 8px;
        }

        textarea {
          width: 100%;
          border: 1px solid #e2e2e2;
          color: #000;
          font-size: 13px;
          padding: 8px 5px;
          margin-bottom: 8px;
          min-height: 122px;
        }

        input {
          &[type="submit"] {
            width: 25%;
            display: block;
            height: 32px;
            float: right;
            border: none;
            margin-right: 15px;
            cursor: pointer;
            background-color: #e1e1e1;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
          }

          &[type="button"] {
            width: 25%;
            display: block;
            height: 32px;
            float: right;
            border: none;
            cursor: pointer;
            background-color: #e1e1e1;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
          }
        }
      }

      .carousel-inner .item img {
        width: 100%;
      }

      .carousel-control-next, .carousel-control-prev {
        opacity: 0.8;
      }

      .carousel-caption {
        bottom: 0px;
        background-color: rgba(0, 0, 0, 0.7);
        font-size: 13px;
        height: 31px;
        left: 0;
        padding: 7px 0;
        right: 0;
        width: 100%;
      }

      .typography-text {
        padding: 0px 0px;
        font-size: 14px;

        ul {
          padding: 0px 0px;
          margin: 0px;
        }

        p {
          text-align: justify;
          line-height: 24px;
          color: #656565;
        }

        ul li {
          display: block;
          padding: 2px 0px;
          line-height: 22px;

          a {
            color: #656565;
          }
        }
      }
    }
  }
}

/* ================== Desktop Drop Down Menu CSS ================== */

/* ================== Desktop Mega Menus CSS  ================== */

@media (min-width: 1024px) {
  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list:first-child {
    border: 0;
  }
}

/*= Desktop Half Menu CSS =*/

/*= Desktop HTML Form Menu CSS =*/

/* ================== Desktop Extra CSS ================== */

.horizontal-header {
  display: none;
}

.horizontal-overlapbg {
  opacity: 0;
  visibility: hidden;
}

.horizontalMenu {
  .horizontalMenu-click, .horizontalMenu-click02, .horizontalMenu-click03, .horizontalMenu-click04 {
    display: none;
  }
}

.hometext {
  display: none;
}

/*==============================================================================
                              Start Mobile CSS
===============================================================================*/

/* ================== Mobile Menu Change Brake Point ================== */

@media only screen and (max-width: 991px) {
  /* ================== Mobile Base CSS ================== */

  body.active {
    overflow: hidden;
  }

  /* ================== Mobile Main Menu CSS ================== */

  .desktoplogo {
    display: none;
  }

  .horizontal-main {
    height: 0;
    padding: 0;
  }

  .horizontalMenucontainer {
    background-attachment: local;
    background-position: 33% 0%;
  }
  .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a{
    font-size: 12px;
  }
  .mega-menubg{
    padding:0px 5px !important;
    box-shadow: none !important;
    h3{
      font-size: 13px !important;
    }
  }
  .horizontalMenu {
    width: 100%;
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    left: 0;
    overflow-y: hidden;
    padding: 0;
    top: 0;
    position: fixed;
    visibility: hidden;
    margin: 0px;

    > .horizontalMenu-list {
      height: auto;
      min-height: 100%;
      width: 240px;
      background: #fff;
      padding-bottom: 0;
      margin-left: -240px;
      display: block;
      text-align: center;

      > li {
        width: 100%;
        display: block;
        float: none;
        border-right: none;
        background-color: transparent;
        position: relative;
        white-space: inherit;
        clear: right;
        padding: 0;

        span a {
          width: 85%;
          margin: 0 auto;
        }

        > a {
          padding: 8px 32px 8px 17px;
          font-size: 13px;
          text-align: left;
          border-right: solid 0px;
          color: #25252a;
          line-height: 25px;
          border-bottom: 1px solid;
          border-bottom-color: #eaedf1;
          position: static;
          margin: 0;
          border-radius: 0px;
          i{
            font-size: 13px;
          }
          span.i {
            display: none;
          }
        }

        a.menuhomeicon {
          padding-left: 17px;
          padding-right: 17px;
          border-top: solid 1px rgba(0, 0, 0, 0.13);
        }

        > a {
          > .fa {
            font-size: 16px;
            color: #fff;
          }

          .arrow:after {
            display: none;
          }
        }

        &:hover > a {
          text-decoration: none;
        }

        > {
          a > .hometext {
            display: inline-block;
          }

          ul.sub-menu {
            display: none;
            position: relative;
            top: 0px;
            background-color: #fff;
            border: none;
            padding: 0px;
            opacity: 1;
            visibility: visible;
            -webkit-transform: none;
            -moz-transform: none;
            -ms-transform: none;
            -o-transform: none;
            transform: none;
            -webkit-transition: inherit;
            -moz-transition: inherit;
            transition: inherit;
            -webkit-transform-style: flat;
            box-shadow: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.03);

            > li {
              > a {
                line-height: 20px;
                font-size: 12px;
                padding: 10px 0px 10px 16px;
                color: #76839a;
              }

              span + a {
                padding-right: 30px;
              }

              > a {
                > .fa {
                  display: none;
                }

                &:hover {
                  background-color: none;
                  color: #25252a;
                  text-decoration: none;
                }
              }
            }

            li:hover > a {
              color: #25252a;
            }

            > li > ul.sub-menu {
              width: 100%;
              position: static;
              left: 100%;
              top: 0;
              display: none;
              margin: 0px;
              padding: 0px;
              border: solid 0px;
              transform: none;
              opacity: 1;
              visibility: visible;
              -webkit-transform: none;
              -moz-transform: none;
              -ms-transform: none;
              -o-transform: none;
              transform: none;
              -webkit-transition: inherit;
              -moz-transition: inherit;
              transition: inherit;
              -webkit-transform-style: flat;

              > li {
                margin: 0px 0px 0px 0px;
                padding: 0px;
                position: relative;

                > a {
                  line-height: 20px;
                  font-size: 13px;
                  padding: 10px 0px 10px 26px;
                  color: #25252a;
                }

                span + a {
                  padding-right: 30px;
                }

                > a {
                  > .fa {
                    display: none;
                  }

                  &:hover {
                    background-color: #e7e7e7;
                    color: #25252a;
                    text-decoration: none;
                  }

                  &.active {
                    color: #000;
                  }
                }

                &:hover > a {
                  color: #000;
                }

                > ul.sub-menu {
                  width: 100%;
                  position: static;
                  left: 100%;
                  top: 0;
                  display: none;
                  margin: 0px;
                  padding: 0px;
                  border: solid 0px;
                  transform: none;
                  opacity: 1;
                  visibility: visible;
                  -webkit-transform: none;
                  -moz-transform: none;
                  -ms-transform: none;
                  -o-transform: none;
                  transform: none;
                  -webkit-transition: inherit;
                  -moz-transition: inherit;
                  transition: inherit;
                  -webkit-transform-style: flat;

                  > li {
                    margin: 0px 0px 0px 0px;

                    > a {
                      line-height: 20px;
                      font-size: 13px;
                      padding: 10px 0px 10px 34px;
                      color: #383838;
                    }

                    span + a {
                      padding-right: 30px;
                    }

                    > a {
                      > .fa {
                        display: none;
                      }

                      &:hover {
                        background-color: #e7e7e7;
                        color: #25252a;
                        text-decoration: none;
                      }

                      &.active {
                        color: #000;
                      }
                    }
                  }
                }
              }
            }
          }

          .horizontal-megamenu {
            .container {
              padding: 0;
            }

            color: #25252a;
            display: none;
            position: relative;
            top: 0px;
            padding: 0px 0px;
            border: solid 0px;
            transform: none;
            opacity: 1;
            visibility: visible;
            -webkit-transform: none;
            -moz-transform: none;
            -ms-transform: none;
            -o-transform: none;
            transform: none;
            -webkit-transition: inherit;
            -moz-transition: inherit;
            transition: inherit;
            border-bottom: 1px solid rgba(0, 0, 0, 0.13);
            -webkit-transform-style: flat;

            &.halfmenu {
              width: 100%;
              margin: 0px;
              padding: 5px 0px 10px 0px;
            }

            .title {
              color: #25252a;
              font-size: 15px;
              padding: 10px 8px 10px 0px;
            }

            > ul {
              width: 100%;
              margin: 0px;
              padding: 0px;
              font-size: 13px;

              > li > a {
                padding: 9px 14px;
                line-height: normal;
                font-size: 13px;
                background-color: #e7e7e7;
                color: #25252a;

                &:hover {
                  background-color: #000000;
                }
              }
            }

            ul li.title {
              line-height: 26px;
              color: #25252a;
              margin: 0px;
              font-size: 15px;
              padding: 7px 0px;
              background-color: transparent;
            }

            &.halfdiv {
              width: 100%;
            }

            .menu_form {
              padding: 5px 0px 62px 0px;
            }

            .show-grid div {
              margin: 0px;
            }

            .menu_form {
              input {
                &[type="button"], &[type="submit"] {
                  width: 46%;
                }
              }

              textarea {
                min-height: 100px;
              }
            }
          }
        }
      }
    }
  }

  /* ================== Mobile Slide Down Links CSS ================== */

  /* ================== Mobile Mega Menus CSS  ================== */

  /* ================== Mobile Header CSS ================== */

  .horizontal-header {
    width: 100%;
    display: block;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    height: 54px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  .active .horizontal-header {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  .horizontal-header .smllogo {
    display: inline-block;
    margin-top: 11px;
  }

  /* Mobile Search Bar*/

  .horizontalMenu > .horizontalMenu-list > li.rightmenu > .topmenusearch {
    width: 86%;
    margin: 7% 7%;
    padding: 0px;

    input {
      border-radius: 50px;

      &:focus {
        width: 100%;
      }
    }
  }

  .callusbtn {
    color: #a9a9a9;
    font-size: 21px;
    position: absolute;
    right: 5px;
    top: 0px;
    transition: all 0.4s ease-in-out 0s;
    z-index: 102;
    padding: 11px 14px;

    .fa {
      vertical-align: top;
      margin-top: 4px;
    }

    &:hover .fa {
      color: #a9a9a9;
    }
  }

  /* Mobile Toggle Menu icon (X ICON) */

  .animated-arrow {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
    padding: 16px 35px 16px 0px;
    margin: 11px 0 0 15px;

    span {
      cursor: pointer;
      height: 2px;
      width: 17px;
      background: #76839a;
      position: absolute;
      display: block;
      content: '';

      &:before, &:after {
        cursor: pointer;
        height: 2px;
        width: 17px;
        background: #76839a;
        position: absolute;
        display: block;
        content: '';
      }

      &:before {
        top: -7px;
        width: 23px;
      }

      &:after {
        bottom: -7px;
        width: 17px;
      }

      transition: all 500ms ease-in-out;

      &:before, &:after {
        transition: all 500ms ease-in-out;
      }
    }
  }

  .active .animated-arrow {
    span {
      &:after {
        width: 23px;
        top: -3px;
      }

      background-color: transparent;

      &:before {
        top: -3px;
      }
    }

    &.active span:after {
      top: -3px;
    }

    span {
      &:before {
        transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        bottom: 0px;
      }

      &:after {
        transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  }

  /* ================== Mobile Overlay/Drawer CSS ================== */

  .horizontal-overlapbg {
    right: 0;
    width: calc(100% - 240px);
    height: 100vh;
    min-height: 100%;
    position: fixed;
    top: 0;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
  }

  .active .horizontalMenu .horizontal-overlapbg {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 1.5s ease-in-out;
    -moz-transition: opacity 1.5s ease-in-out;
    -ms-transition: opacity 1.5s ease-in-out;
    -o-transition: opacity 1.5s ease-in-out;
  }

  .horizontalMenucontainer {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  .active {
    .horizontalMenucontainer {
      margin-left: 240px;
      -webkit-transition: all 0.25s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;
      -o-transition: all 0.25s ease-in-out;
      -ms-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;
    }

    .horizontalMenu {
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      visibility: visible;
      z-index: 1000;
      top: 0;
      height: 100% !important;

      > .horizontalMenu-list {
        -webkit-transition: all 0.25s ease-in-out;
        -moz-transition: all 0.25s ease-in-out;
        -o-transition: all 0.25s ease-in-out;
        -ms-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out;
        margin-left: 0;
      }
    }
  }

  /* ================== Mobile Sub Menu Expander Arrows  ================== */

  .horizontalMenu > .horizontalMenu-list > li {
    > .horizontalMenu-click {
      height: 49px;
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      cursor: pointer;
      width: 100%;
    }

    .horizontalMenu-click.horizontal-activearrow > i {
      transform: rotate(180deg);
    }

    > {
      .horizontalMenu-click > i {
        display: block;
        height: 23px;
        width: 25px;
        margin-top: 12px;
        margin-right: 10px;
        background-size: 25px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.2);
        float: right;
      }

      ul.sub-menu > li .horizontalMenu-click02 { 
        height: 41px;
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        cursor: pointer;
        width: 100%;

        > i {
          display: block;
          height: 23px;
          width: 25px;
          margin: 8px 6px 8px 6px;
          background-size: 25px;
          font-size: 21px;
          color: rgba(255, 255, 255, 0.25);
          float: right;

          &.horizontalMenu-rotate {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }
      }
      ul.sub-menu > li .horizontalMenu-click03 { 
        height: 41px;
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        cursor: pointer;
        width: 100%;

        > i {
          display: block;
          height: 23px;
          width: 25px;
          margin: 8px 6px 8px 6px;
          background-size: 25px;
          font-size: 21px;
          color: rgba(255, 255, 255, 0.25);
          float: right;

          &.horizontalMenu-rotate {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }
      }
      ul.sub-menu > li .horizontalMenu-click04 { 
        height: 41px;
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        cursor: pointer;
        width: 100%;

        > i {
          display: block;
          height: 23px;
          width: 25px;
          margin: 8px 6px 8px 6px;
          background-size: 25px;
          font-size: 21px;
          color: rgba(255, 255, 255, 0.25);
          float: right;

          &.horizontalMenu-rotate {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }
      }
    }

    a span {
      display: none;
    }

    > {
      a .horizontal-icon {
        display: none;
      }

      ul.sub-menu > li > ul.sub-menu {
        background-color: rgba(0, 0, 0, 0.05);
        box-shadow: none !important;
      }

      .horizontal-megamenu .link-list li a {
        color: rgba(255, 255, 255, 0.6);
        background-color: #3d357d;
      }
    }
  }

  .mega-menubg {
    border: 0 !important;
    box-shadow: 0 !important;
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li > {
    .horizontal-megamenu .link-list li a {
      background: #fff !important;
      color: #76839a;
    }

    a.active {
      color: #fff;
      background: $primary-1;
    }
  }

  /*End Media Query*/
}

/* Extra @Media Query*/

@media only screen and (min-width: 992px) and (max-width: 1162px) {
  .desktoplogo {}

  .horizontalMenu > .horizontalMenu-list > li {
    > a {
      > .arrow {
        display: none;
      }

      padding-left: 5px;
      padding-right: 5px;
    }

    a.menuhomeicon {
      padding-left: 22px;
      padding-right: 22px;
    }
  }
}

.horizontalMenu-list .sub-menu .sub-menu {
  &:before, &:after {
    display: none;
  }
}

@media (max-width: 992px) {
  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .megamenu-content {
    border: 0;
    box-shadow: none;
  }
}

.horizontalMenu ul li a.btn {
  font-size: 14px !important;
  margin: 5px 0;
}

@media (min-width: 992px) {
  .horizontalMenu > .horizontalMenu-list > li {
    > ul.sub-menu {
      opacity: 0;
      visibility: hidden;
      -o-transform-origin: 0% 0%;
      -ms-transform-origin: 0% 0%;
      -moz-transform-origin: 0% 0%;
      -webkit-transform-origin: 0% 0%;
      -o-transition: -o-transform 0.3s, opacity 0.3s;
      -ms-transition: -ms-transform 0.3s, opacity 0.3s;
      -moz-transition: -moz-transform 0.3s, opacity 0.3s;
      -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
      transform-style: preserve-3d;
      -o-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      -webkit-transform-style: preserve-3d;
      transform: rotateX(-75deg);
      -o-transform: rotateX(-75deg);
      -moz-transform: rotateX(-75deg);
      -webkit-transform: rotateX(-75deg);
    }

    &:hover {
      > ul.sub-menu, > .horizontal-megamenu {
        opacity: 1;
        visibility: visible;
        transform: rotateX(0deg);
        -o-transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        -webkit-transform: rotateX(0deg);
      }
    }

    > {
      ul.sub-menu > li {
        > ul.sub-menu {
          opacity: 0;
          visibility: hidden;
          transform-style: preserve-3d;
          -o-transform-style: preserve-3d;
          -moz-transform-style: preserve-3d;
          -webkit-transform-style: preserve-3d;
          transform: rotateX(-75deg);
          -o-transform: rotateX(-75deg);
          -moz-transform: rotateX(-75deg);
          -webkit-transform: rotateX(-75deg);
        }

        &:hover > ul.sub-menu {
          opacity: 1;
          visibility: visible;
          -o-transform-origin: 0% 0%;
          -ms-transform-origin: 0% 0%;
          -moz-transform-origin: 0% 0%;
          -webkit-transform-origin: 0% 0%;
          -o-transition: -o-transform 0.4s, opacity 0.4s;
          -ms-transition: -ms-transform 0.4s, opacity 0.4s;
          -moz-transition: -moz-transform 0.4s, opacity 0.4s;
          -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
          transform: rotateX(0deg);
          -o-transform: rotateX(0deg);
          -moz-transform: rotateX(0deg);
          -webkit-transform: rotateX(0deg);
        }

        > ul.sub-menu > li {
          > ul.sub-menu {
            opacity: 0;
            visibility: hidden;
            -o-transform-origin: 0% 0%;
            -ms-transform-origin: 0% 0%;
            -moz-transform-origin: 0% 0%;
            -webkit-transform-origin: 0% 0%;
            -o-transition: -o-transform 0.4s, opacity 0.4s;
            -ms-transition: -ms-transform 0.4s, opacity 0.4s;
            -moz-transition: -moz-transform 0.4s, opacity 0.4s;
            -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
            transform-style: preserve-3d;
            -o-transform-style: preserve-3d;
            -moz-transform-style: preserve-3d;
            -webkit-transform-style: preserve-3d;
            transform: rotateX(-75deg);
            -o-transform: rotateX(-75deg);
            -moz-transform: rotateX(-75deg);
            -webkit-transform: rotateX(-75deg);
          }

          &:hover > ul.sub-menu {
            opacity: 1;
            visibility: visible;
            transform: rotateX(0deg);
            -o-transform: rotateX(0deg);
            -moz-transform: rotateX(0deg);
            -webkit-transform: rotateX(0deg);
          }
        }
      }

      .horizontal-megamenu {
        opacity: 0;
        visibility: hidden;
        -o-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -webkit-transform-origin: 0% 0%;
        -o-transition: -o-transform 0.3s, opacity 0.3s;
        -ms-transition: -ms-transform 0.3s, opacity 0.3s;
        -moz-transition: -moz-transform 0.3s, opacity 0.3s;
        -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
        transform-style: preserve-3d;
        -o-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;
        transform: rotateX(-75deg);
        -o-transform: rotateX(-75deg);
        -moz-transform: rotateX(-75deg);
        -webkit-transform: rotateX(-75deg);
      }
    }
  }

  .header-style1 .horizontalMenu > .horizontalMenu-list > li {
    padding: 0.75rem 0;
  }
}

.mega-menubg {
  background: #fff;
  padding: 14px 5px;
  box-shadow: 0 10px 15px 0 rgba(104, 113, 123 , .3);
  border: 1px solid #f0f0f4;
}

.horizontal-main.hor-menu {
  background: #fff;
  border-bottom: 1px solid #eaedf1;
}

.hor-menu .horizontalMenu > .horizontalMenu-list > li {
  > .horizontal-megamenu .link-list li a {
    background: #fff;
    color: #76839a;
  }

  &:first-child {
    border-left: 1px solid #eaedf1;
  }

  &:last-child {
    border-right: 1px solid #eaedf1;
  }

  > a {
    &.active, &:hover {
      color: $primary-1;
      background: #fff !important;
    }
  }
}

.horizontalMenu > .horizontalMenu-list > li > {
  ul.sub-menu {
    .sub-menu-sub:after {
      content: '\e92f';
      font-family: 'feather';
      font-size: 13px;
      position: absolute;
      top: 9px;
      right: 14px;
      color: #121029;
      opacity: 0.4;
    }

    > li > {
      a:hover, ul.sub-menu > li > a:hover {
        color: $primary-1;
      }
    }
  }

  .horizontal-megamenu .link-list li a:hover {
    color: $primary-1;
  }
}

.sub-menu li a:before, .mega-menubg li a:before {
  content: '\e92f';
  margin-right: 5px;
  position: relative;
  font-family: 'feather' !important;
  color: #68798b;
  font-size: 11px;
}
.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a.active{
 color:#6259ca;
}
.hor-menu .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a.active{
  color:#6259ca;
}